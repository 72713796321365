import Cookies from 'js-cookie'

export const setToken = (token: any) => {
    Cookies.set('Token', token)
}

export const getToken = () => {
    return Cookies.get('Token')
}

export const cleanToken = () => {
    Cookies.remove('Token')
}

export const setUserInfo = (info: any) => {
    localStorage.setItem('UerInfo', JSON.stringify(info))
}

export const getUserInfo = () => {
    let u = localStorage.getItem('UerInfo')
    if (u === null) {
        const targetUrl = window.location.href;
        window.location.href = window.location.origin + `/login?targetUrl=${targetUrl}`
        return {}
    }
    return JSON.parse(u)
}

export const clearUserInfo = () => {
    localStorage.removeItem('UerInfo')
}


export const addMoneyUnit = (money: number) => {
    if (money >= 10000) {
        return (money / 1000).toFixed(1) + 'W'
    }
    return money
}
