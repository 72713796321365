import React, { useState, useEffect, forwardRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { DatePicker, TimePicker, Form, InputNumber, Radio, Space, Select, message, Input } from 'antd';
import type { SelectProps } from 'antd';
import { statusEnum, statusEnumKeys, productTypeEnum, productTypeEnumKeys } from '../../common/enum'
import type { Moment } from 'moment';
import { list as userList } from '../../common/api/user'
import { list as courseList } from '../../common/api/course'
import { list as storeList } from '../../common/api/store'


const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const ProductDetail = forwardRef((props: any, ref: any) => {
    const [form] = Form.useForm();
    const [userEnum, setUserEnum] = useState<SelectProps['options']>([]);
    const [courseEnum, setCourseEnum] = useState<SelectProps['options']>([]);
    const [storeEnum, setStoreEnum] = useState<SelectProps['options']>([]);

    useEffect(() => {
        GetUserEnum()
        GetCourseEnum()
        GetStoreEnum()
        form.resetFields()
        form.setFieldsValue(props.data)
    }, [props.data])

    const GetUserEnum = async () => {
        try {
            const res: any = await userList({ "page": 1, "size": 1000, "query": JSON.stringify({ "type": "coache" })  })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            const data = res.user_list.map((item: any) => ({
                value: item.id,
                label: item.nickname,
            }));
            setUserEnum(data)
        } catch (error) {
            message.info("GetUserEnum catch err")
        }
    };

    const GetCourseEnum = async () => {
        try {
            const res: any = await courseList({ "page": 1, "size": 1000 })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            const data = res.course_list.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));
            setCourseEnum(data)
        } catch (error) {
            message.info("GetCourseEnum catch err")
        }
    };

    const GetStoreEnum = async () => {
        try {
            const res: any = await storeList({ "page": 1, "size": 1000 })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            const data = res.store_list.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));
            setStoreEnum(data)
        } catch (error) {
            message.info("GetStoreEnum catch err")
        }
    };

    return (
        <Form
            ref={ref}
            name='store'
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={props.data}
            layout="horizontal"
            style={{ paddingTop: 20 }}
            onFinish={props.onFinish}
        >
            <Form.Item label="类型" name="type" initialValue="normal">
                <Select style={{ minWidth: '100px' }} placeholder="请选择类型">
                    {productTypeEnumKeys.map(key => <Select.Option value={key}>{productTypeEnum[key]}</Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="商品价格" name="price" initialValue="76">
                <InputNumber />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
                {({ getFieldValue }) =>
                    getFieldValue('type') === 'normal' ? (
                        <>
                            <Form.Item label="所属课程" name="course_id">
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    notFoundContent={null}
                                    options={courseEnum}
                                />
                            </Form.Item>
                            <Form.Item label="开课门店" name={["store", "store_id"]}>
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    notFoundContent={null}
                                    options={storeEnum}
                                />
                            </Form.Item>
                            <Form.Item label="开课教练" name={["store", "coache_id"]}>
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    notFoundContent={null}
                                    options={userEnum}
                                />
                            </Form.Item>
                            <Form.Item label="开课时间">
                                <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item noStyle name={["store", "date"]}>
                                        <DatePicker />
                                    </Form.Item>
                                    <Form.Item noStyle name={["store", "time"]}>
                                        <TimePicker minuteStep={5} disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 22, 23]} format="HH:mm" />
                                    </Form.Item>
                                </Space>
                            </Form.Item>
                            <Form.Item name="inventory" label="库存" initialValue="6">
                                <InputNumber />
                            </Form.Item>
                        </>
                    ) : (<>
                        <Form.Item label="所属课程" hidden name="course_id">
                            <Input value="all"/>
                        </Form.Item>
                        <Form.Item label="次卡次数" name={["visit_card", "visits"]} initialValue="10">
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="有效天数" name={["visit_card", "expiration_day"]} initialValue="60">
                            <InputNumber />
                        </Form.Item>
                    </>)
                }
            </Form.Item>
            <Form.Item label="状态" name="status" initialValue="active">
                <Radio.Group>
                    {statusEnumKeys.map(key => <Radio value={key}>{statusEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>
        </Form>
    );
})
export default ProductDetail;
