import React, { useState, useEffect } from 'react';
import { DatePicker, Button, Form, Input, Select, Table, Space, Modal, message, Image } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import UserDetail from './detail';
import dayjs from 'dayjs'
import { statusEnum, statusEnumKeys, userGenderEnum, userGenderEnumKeys, userTypeEnum, userTypeEnumKeys } from '../../common/enum'
import { UserDataType as DataType } from '../../common/type'
import { action, list } from '../../common/api/user'

const User: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState<DataType[]>([]);
  const detail = React.createRef<typeof form>();
  useEffect(() => {
    GetList()
  }, [])
  useEffect(() => {
    if (data !== undefined) {
      showModal()
    }
  }, [data])

  const columns: TableColumnsType<DataType> = [
    {
      title: '昵称',
      dataIndex: 'nickname',
    },
    {
      title: '头像',
      dataIndex: 'avatar',
      render: (text) => <Image width="20px" src={text} />,
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
    },
    {
      title: '性别',
      dataIndex: 'gender',
      render: (text) => userGenderEnum[text],
    },
    {
      title: '生日',
      dataIndex: 'birthday',
      render: (text) => text ? dayjs(text).format("YYYY-MM-DD") : ""
    },
    {
      title: '类型',
      dataIndex: 'type',
      render: (text) => userTypeEnum[text],
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text) => statusEnum[text],
    },
    {
      title: '课包(剩余次数/到期日期)',
      render: (_, record) => record.visit_card ? (record.visit_card.remaining_visits || 0) + " / " + (dayjs(record.visit_card.end_date).format("YYYY-MM-DD")) : "- / -",
    },
    {
      title: '运动(月次数/总次数)',
      render: (_, record) => (record.month_motion || 0) + " / " + (record.accumulate_motion || 0),
    },
    {
      title: '注册时间',
      dataIndex: 'create_at',
      render: (_, record) => record.create_at,
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => <><a href='#edit' onClick={() => { onDetailEdit(record); }}>编辑</a></>,
    },
  ];
  const GetList = async () => {
    try {
      const params = form.getFieldsValue()
      const res: any = await list({ "page": 1, "size": 20, "query": JSON.stringify(params) })
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      setDataList(res.user_list)
    } catch (error) {
      message.info("GetList catch err")
    }
  }

  const showAdd = () => {
    setData(undefined);
    showModal();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setData(undefined)
    detail.current?.resetFields()
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onDetailEdit = (record: any) => {
    var value = JSON.parse(JSON.stringify(record));
    if (value.birthday) {
      value.birthday = dayjs(value.birthday, "YYYY-MM-DD")
    }
    if (value["res"]) {
      for (const key in value["res"]) {
        value["res"][key] = JSON.parse(value["res"][key]);
      }
    }
    setData(value)
  }

  const onDetailFinish = async (value: any) => {
    if (data !== undefined) {
      value["id"] = data.id
    }
    if (value["birthday"]) {
      value["birthday"] = dayjs(value["birthday"]).format("YYYY-MM-DD")
    }
    if (value["res"]) {
      for (const key in value["res"]) {
        value["avatar"] = value["res"][key]["url"];
        value["res"][key] = JSON.stringify(value["res"][key]);
      }
    }
    try {
      const res: any = await action(1, value)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      detail.current?.resetFields()
      setOpen(false)
      setData(undefined)
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailFinish catch err")
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 16, textAlign: "left" }}>
        <Form
          layout="inline"
          form={form}
          initialValues={{ layout: "inline" }}
          onFinish={GetList}
        >
          <Form.Item label="呢称" name="nickname">
            <Input style={{ width: '120px' }} placeholder="请输入要查询的订单号" />
          </Form.Item>
          <Form.Item label="电话" name="phone">
            <Input style={{ width: '120px' }} placeholder="请输入要查询的电话" />
          </Form.Item>
          <Form.Item label="生日" name="birthday">
            <DatePicker />
          </Form.Item>
          <Form.Item label="性别" name="gender">
            <Select style={{ width: '80px' }} placeholder="请选择要查询的性别" allowClear>
              {userGenderEnumKeys.map(key => <Select.Option value={key}>{userGenderEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="类型" name="type">
            <Select style={{ width: '100px' }} placeholder="请选择要查询的类型" allowClear>
              {userTypeEnumKeys.map(key => <Select.Option value={key}>{userTypeEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="状态" name="status" initialValue="active">
            <Select style={{ width: '80px' }} placeholder="请选择要查询的状态" allowClear>
              {statusEnumKeys.map(key => <Select.Option value={key}>{statusEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary" size="small" shape="circle" icon={<SearchOutlined />} />
              <Button onClick={showAdd} type="primary" size="small" shape="circle" icon={<PlusOutlined />} />
            </Space>
          </Form.Item>
        </Form>

      </div>
      <Table rowKey="id" size="small" bordered rowSelection={rowSelection} columns={columns} dataSource={dataList} pagination={{ showSizeChanger: true }} />
      <Modal maskClosable={false} open={open} onCancel={handleCancel} title={data?.id ? "编辑" : "添加"} onOk={() => {
        if (detail.current) {
          detail.current.submit()
        }
      }}>
        <UserDetail ref={detail} data={data} onFinish={onDetailFinish}></UserDetail>
      </Modal>
    </div>
  );
};

export default User;