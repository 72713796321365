import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Table, Space, Modal, message } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import type { SelectProps, TableColumnsType } from 'antd';
import OrderDetail from './detail';
import dayjs from 'dayjs';
import { orderStatusEnum, orderStatusEnumKeys, productTypeEnum, productTypeEnumKeys } from '../../common/enum'
import { SubOrderDataType as DataType } from '../../common/type'
import { list as userList } from '../../common/api/user'
import { list as courseList } from '../../common/api/course'
import { list as storeList } from '../../common/api/store'
import { action, list } from '../../common/api/order'



const Order: React.FC = () => {
  const [form] = Form.useForm();

  const [userValue, setUserValue] = useState<any>({});
  const [courseValue, setCourseValue] = useState<any>({});
  const [storeValue, setStoreValue] = useState<any>({});
  
  const [userEnum, setUserEnum] = useState<SelectProps['options']>([]);
  const [coacheEnum, setCoacheEnum] = useState<SelectProps['options']>([]);
  const [courseEnum, setCourseEnum] = useState<SelectProps['options']>([]);
  const [storeEnum, setStoreEnum] = useState<SelectProps['options']>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState<DataType[]>([]);
  
  const detail = React.createRef<typeof form>();
  useEffect(() => {
    GetUserEnum()
    GetCourseEnum()
    GetStoreEnum()
    GetList()
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      showModal()
    }
  }, [data])

  const columns: TableColumnsType<DataType> = [
    {
      title: '订单编号',
      dataIndex: 'sub_order_no',
      width: "80px",
    },
    {
      title: '购买顾客',
      dataIndex: 'user_id',
      render: (text) => userValue[text],
    },
    {
      title: '购买课程',
      dataIndex: 'course_id',
      render: (text) => courseValue[text],
    },
    {
      title: '订单状态',
      dataIndex: 'status',
      render: (text) => orderStatusEnum[text],
    },
    {
      title: '预约门店',
      render: (_,record) => record.product_type === "normal"?storeValue[record.store_id]:"",
    },
    {
      title: '预约教练',
      render: (_,record) => record.product_type === "normal"?userValue[record.coache_id]:"",
    },
    {
      title: '预约时间',
      render: (_,record) => record.product_type === "normal"?record.appoint_at:"-",
    },
    {
      title: '核销时间',
      dataIndex: 'complet_at',
      render: (_,record) => record.product_type === "normal" && record.status === "completed" ?record.complet_at:"-",
    },
    {
      title: '创建时间',
      dataIndex: 'create_at',
    },
    // {
    //   title: '操作',
    //   dataIndex: 'action',
    //   fixed: 'right',
    //   width: 100,
    //   render: (_, record) => <><a href='#edit' onClick={() => { onDetailEdit(record); }}>编辑</a></>,
    // },
  ];

    const GetUserEnum = async () => {
        try {
            const res: any = await userList({ "page": 1, "size": 1000 })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            let data: any = {}
            let cEnum: SelectProps['options'] = []
            let uEnum: SelectProps['options'] = []
            res.user_list.forEach((item: any) => {
              data[item.id] = item.nickname
              if(cEnum && item.type == 'coache'){
                cEnum.push({
                  value: item.id,
                  label: item.nickname,
                })
              }
              if(uEnum && item.type == 'user'){
                uEnum.push({
                  value: item.id,
                  label: item.nickname,
                })
              }
            });
            setUserValue(data)
            setUserEnum(uEnum)
            setCoacheEnum(cEnum)
        } catch (error) {
            message.info("GetUserEnum catch err")
        }
    };

    const GetCourseEnum = async () => {
        try {
            const res: any = await courseList({ "page": 1, "size": 1000 })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            let data: any = {}
            let dataEnum: SelectProps['options'] = []
            res.course_list.forEach((item: { id: string; name: string; }) => {
              data[item.id] = item.name
              if(dataEnum){
                dataEnum.push({
                  value: item.id,
                  label: item.name,
                })
              }
            });
            setCourseValue(data)
            setCourseEnum(dataEnum)
        } catch (error) {
            message.info("GetCourseEnum catch err")
        }
    };

    const GetStoreEnum = async () => {
        try {
            const res: any = await storeList({ "page": 1, "size": 1000 })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            let data: any = {}
            let dataEnum: SelectProps['options'] = []
            res.store_list.forEach((item: any) => {
              data[item.id] = item.name
              if(dataEnum){
                dataEnum.push({
                  value: item.id,
                  label: item.name,
                })
              }
            });
            setStoreValue(data)
            setStoreEnum(dataEnum)
        } catch (error) {
            message.info("GetStoreEnum catch err")
        }
    };

  const GetList = async () => {
    try {
      const params = form.getFieldsValue()
      const res: any = await list({ "page": 1, "size": 20, "query": JSON.stringify(params) })
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      setDataList(res.order_list)
    } catch (error) {
      message.info("GetList catch err")
    }
  }

  const showAdd = () => {
    setData(undefined);
    showModal();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setData(undefined)
    detail.current?.resetFields()
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const onDetailEdit = (record: any) => {
  //   var value = JSON.parse(JSON.stringify(record));
  //   if(value["store"] && value["store"]["date_time"]){
  //     value["store"]["date"] = dayjs(value["store"]["date_time"], "YYYY-MM-DD")
  //     value["store"]["time"] = dayjs(value["store"]["date_time"], "HH:mm")
  //   }
  //   setData(value)
  // }

  const onDetailFinish = async (value: any) => {
    console.log("value", value)
    if (data !== undefined) {
      value["id"] = data.id
    }
    if(value["price"]){
      value["price"] = parseFloat(value["price"])
    }
    if(value["type"] === 'normal' && value["store"] && value["store"]["date"] && value["store"]["time"]){
      value["store"]["course_id"] = value["course_id"]
      value["store"]["date_time"] = dayjs(value["store"]["date"]).format("YYYY-MM-DD") +" "+ dayjs(value["store"]["time"]).format("HH:mm")
      delete value["store"]["date"]
      delete value["store"]["time"]
    }
    if(value["type"] === 'visit_card' && value["visit_card"]){
      value["visit_card"]["course_id"] = value["course_id"]
    }
    try {
      const res: any = await action(1, value)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      detail.current?.resetFields()
      setOpen(false)
      setData(undefined)
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailFinish catch err")
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 16, textAlign: "left" }}>
      <Form
          layout="inline"
          form={form}
          initialValues={{ layout: "inline" }}
          onFinish={GetList}
        >
          <Form.Item label="订单号" name="sub_order_no">
            <Input placeholder="请输入要查询的订单号" />
          </Form.Item>
          <Form.Item label="购买课程" name="course_id">
            <Select
              style={{ minWidth: '140px' }}
              showSearch
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              notFoundContent={null}
              options={courseEnum}
              allowClear
            />
          </Form.Item>
          <Form.Item label="订单状态" name="status" initialValue="pending_complet">
            <Select style={{minWidth: '200px'}} placeholder="请选择要查询的状态" allowClear>
              {orderStatusEnumKeys.map(key => <Select.Option value={key}>{orderStatusEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="预约门店" name="store_id">
            <Select
              style={{ minWidth: '140px' }}
              showSearch
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              notFoundContent={null}
              options={storeEnum}
              allowClear
            />
          </Form.Item>
          <Form.Item label="预约教练" name="coache_id">
            <Select
              style={{ minWidth: '140px' }}
              showSearch
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              notFoundContent={null}
              options={coacheEnum}
              allowClear
            />
          </Form.Item>
          <Form.Item label="商品类型" name="product_type" initialValue="normal">
            <Select style={{ minWidth: '100px' }} placeholder="请选择要查询的商品类型">
              {productTypeEnumKeys.map(key => <Select.Option value={key}>{productTypeEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary" size="small" shape="circle" icon={<SearchOutlined />} />
              <Button onClick={showAdd} type="primary" size="small" shape="circle" icon={<PlusOutlined />} />
            </Space>
          </Form.Item>
        </Form>
      </div>
      <Table rowKey="id" size="small" bordered rowSelection={rowSelection} columns={columns} dataSource={dataList} pagination={{ showSizeChanger: true }}/>
      <Modal maskClosable={false} open={open} onCancel={handleCancel} title={data?.id ? "编辑" : "添加"} onOk={() => {
        if (detail.current) {
          detail.current.submit()
        }
      }}>
        <OrderDetail ref={detail} data={data} onFinish={onDetailFinish}></OrderDetail>
      </Modal>
    </div>
  );
};

export default Order;