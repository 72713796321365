import React, { useEffect, useState, forwardRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Radio, Select, Upload } from 'antd';
import { statusEnum, statusEnumKeys, userGenderEnum, userGenderEnumKeys, userTypeEnum, userTypeEnumKeys } from '../../common/enum'
import { TencentCOSUpload } from '../../components/object_storage/tencent_cos'
import { token as osToken } from '../../common/api/tool'

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const UserDetail = forwardRef((props: any, ref: any) => {
    const [form] = Form.useForm();
    const [cosData, setCOSData] = useState();
    const [defUploadList, setDefUploadList] = useState<any>();
    useEffect(() => {
        form.resetFields()
        form.setFieldsValue(props.data)
        setDefUploadList(undefined);
        if(props.data && props.data["res"]){
            setDefUploadList(props.data["res"]);
        }
        getToken();
    }, [props.data])

    const getToken = async () => {
        var req: any = await osToken("cos", "public/user/");
        // console.log("data", req.data)
        setCOSData(req.data);
    };
    return (
        <Form
            ref={ref}
            name='store'
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={props.data}
            layout="horizontal"
            style={{ paddingTop: 20 }}
            onFinish={props.onFinish}
        >
            <Form.Item label="类型" name="type" initialValue="user">
                <Radio.Group>
                    {userTypeEnumKeys.map(key => <Radio value={key}>{userTypeEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item label="账号" name="username">
                <Input disabled={props?.data?.id} maxLength={30}/>
            </Form.Item>
            <Form.Item label="昵称" name="nickname">
                <Input />
            </Form.Item>
            <Form.Item label="头像" name="res" valuePropName="fileList" getValueFromEvent={normFile} shouldUpdate={true}>
                <TencentCOSUpload value={defUploadList} onChange={(uploadList)=>{setDefUploadList(uploadList)}} maxCount={1} cosData={cosData}></TencentCOSUpload>
            </Form.Item>
            <Form.Item label="电话" name="phone">
                <Input />
            </Form.Item>
            <Form.Item label="生日" name="birthday">
                <DatePicker />
            </Form.Item>
            <Form.Item label="性别" name="gender">
                <Radio.Group>
                    {userGenderEnumKeys.map(key => <Radio value={key}>{userGenderEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>

            <Form.Item label="状态" name="status" initialValue="active">
                <Radio.Group>
                    {statusEnumKeys.map(key => <Radio value={key}>{statusEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>
        </Form>
    );
})
export default UserDetail;
