import axios from 'axios'
import { getToken, cleanToken, clearUserInfo } from './utils'
// import { Redirect } from 'react-router-dom'

const instance = axios.create({
    baseURL: process.env.REACT_APP_SS6_API_HOST,  //根路径
    timeout: 20000   //请求过期时间
})

// http request 拦截器
instance.interceptors.request.use(
    config => {
        const token = getToken();
        if (token) {
            // 在请求头中设置token
            config.headers.setAuthorization('Bearer ' + token);
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    });


// http response 拦截器
instance.interceptors.response.use(
    res => {
        if (res.data.status_code !== 0) {
            if (res.data.status_code === 10003) {
                cleanToken()
                clearUserInfo()
                if (window.location.pathname !== '/login') {
                    const targetUrl = window.location.href;
                    window.location.href = window.location.origin + `/login?targetUrl=${targetUrl}`
                }
            }
            console.log("response error", res.headers, res.data)
            // return message.error(res.data.errmsg || 'Networke rror!')
        }
        return res.data
    },
    error => {
        return Promise.reject(error.response);
    });

// instance.uploadFile = (url: any, key: any, fun: (arg: any) => void) => {
//     key = key ? key : 'file'
//     return {
//         name: key,
//         action: url,
//         method: "post",
//         headers: {
//             'Authorization': 'Bearer ' + getToken()
//         },
//         onChange(info: any) {
//             console.log(info)
//             var res = info.file.response
//             if (res) {
//                 if (res.status !== 0) {
//                     if (res.status === 401) {
//                         cleanToken()
//                         clearUserInfo()
//                         const targetUrl = window.location.href;
//                         window.location.href = window.location.origin + `/login?targetUrl=${targetUrl}`
//                     }
//                     fun([undefined, res])
//                     return
//                 }
//                 if (typeof (fun) == "function")
//                     fun(res)
//                 return
//             }
//         },
//     }
// }

export default instance;