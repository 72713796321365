import { Navigate } from 'react-router-dom'
import DefaultLayout from '../components/layout/default'
import Dashboard from '../pages/dashboard/index'
import Order from '../pages/order/index'
import User from '../pages/user/index'
import Store from '../pages/store/index'
import Article from '../pages/article/index'
import Course from '../pages/course/index'
import Product from '../pages/product/index'
import Page404 from '../pages/404/index'
import Login from '../pages/login/index'

const routes = [
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/order',
        element: <Order />
      },
      {
        path: '/product',
        element: <Product />
      },
      {
        path: '/user',
        element: <User />
      },
      {
        path: '/course',
        element: <Course />
      },
      {
        path: '/store',
        element: <Store />
      },
      {
        path: '/activity',
        element: <Article />
      },
      {
        path: '/',
        element: <Navigate to='/dashboard' />
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/404',
    element: <Page404 />,
  }
]

export { routes }
