
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { message, Upload } from 'antd';

import { FileLoader, UploadAdapter, UploadResponse } from 'ckeditor5';

interface COSDataType {
  dir: string;
  expire: string;
  host: string;
  qAk: string;
  qKeyTime: string;
  policy: string;
  qSignature: string;
  qSignAlgorithm: string;
}

interface TencentCOSUploadProps {
  value?: UploadFile[];
  cosData?: COSDataType;
  maxCount?: number,
  onChange?: (fileList: UploadFile[]) => void;
}

export const TencentCOSUpload = ({ value, cosData, maxCount, onChange }: TencentCOSUploadProps) => {
  const [COSData, setCOSData] = useState<COSDataType>();

  const init = async () => {
    try {
      setCOSData(cosData);
    } catch (error) {
      message.error(error as string);
    }
  };

  useEffect(() => {
    init();
  }, [cosData]);

  const handleChange: UploadProps['onChange'] = (data: any) => {
    if (data?.file.status) {
      if (data?.file.status === "done") {
        for (var index in data.fileList) {
          data.fileList[index].url = "//sufei.ss6.cos.3it.cc/" + data.fileList[index].url
        }
      }
    }
    onChange?.([...data.fileList]);
  };

  const onRemove = (file: UploadFile) => {
    const files = (value || []).filter((v) => v.url !== file.url);

    if (onChange) {
      onChange(files);
    }
  };

  const getExtraData: UploadProps['data'] = (file) => ({
    'key': file.url,
    'policy': COSData?.policy,
    'q-ak': COSData?.qAk,
    'q-key-time': COSData?.qKeyTime,
    'q-sign-algorithm': COSData?.qSignAlgorithm,
    'q-signature': COSData?.qSignature,
  });

  const beforeUpload: UploadProps['beforeUpload'] = async (file) => {
    if (!COSData) return false;

    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    // @ts-ignore
    file.url = COSData.dir + filename;

    return file;
  };

  const uploadProps: UploadProps = {
    name: 'file',
    fileList: value,
    action: COSData?.host,
    listType: "picture-card",
    maxCount: maxCount ? maxCount : 9,
    onChange: handleChange,
    onRemove,
    data: getExtraData,
    beforeUpload,
  };

  return (
    <Upload {...uploadProps}>
      <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传</div>
      </button>
    </Upload>
  );
};

export class TencentCOSUploadAdapter implements UploadAdapter {
  loader: FileLoader;
  cosData: COSDataType;
  xhr: XMLHttpRequest | undefined;
  file_url: string | undefined;
  constructor(loader: FileLoader, cosData: COSDataType) {
    this.loader = loader;
    this.cosData = cosData;
  }

  upload(): Promise<UploadResponse> {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject, file);
        this._sendRequest(file);
      }));
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();
    xhr.open('POST', this.cosData?.host, true);
    xhr.responseType = 'json';
  }

  _initListeners(resolve: any, reject: any, file: any) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `图片${file.name}上传失败`;
    if (xhr) {
      xhr.addEventListener('error', (e) => reject(genericErrorText));
      xhr.addEventListener('abort', () => reject());
      xhr.addEventListener('load', () => {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          return reject(`图片${file.name}大小超出2M，请重新上传`);
        }
        console.log("response status", xhr.status)
        if (xhr.status != 200 && xhr.status != 204) {
          return reject(genericErrorText);
        }
        console.log("update file", this.file_url);
        resolve({
          // 这里要根据后端接口取图片地址
          default: "//sufei.ss6.cos.3it.cc/" + this.file_url
        });
      });

      if (xhr.upload) {
        xhr.upload.addEventListener('progress', (evt: any) => {
          if (evt.lengthComputable) {
            loader.uploadTotal = evt.total;
            loader.uploaded = evt.loaded;
          }
        });
      }
    }
  }

  _sendRequest(file: any) {
    const data = new FormData();
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    this.file_url = this.cosData?.dir + filename;
    data.append('key', this.file_url);
    data.append('policy', this.cosData?.policy);
    data.append('q-ak', this.cosData?.qAk);
    data.append('q-key-time', this.cosData?.qKeyTime);
    data.append('q-sign-algorithm', this.cosData?.qSignAlgorithm);
    data.append('q-signature', this.cosData?.qSignature);
    data.append('file', file)
    if (this.xhr) {
      this.xhr.send(data);
    }
  }
}


export const TencentCOSUploadPlugin = (editor: any, cosData: COSDataType) => {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: FileLoader) => {
    return new TencentCOSUploadAdapter(loader, cosData);
  };
};
