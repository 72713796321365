import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Input, Button, message} from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { setToken, cleanToken, setUserInfo, clearUserInfo, getToken } from '../../common/utils'
import { login, resetPassword } from '../../common/api/systemuser'

import './index.css'


const LoginForm = () => {
    const [form] = Form.useForm()
    const [loginLoading, setLoginLoading] = useState(false)
    const { pathname, state, search } = useLocation()
    const isLogin = pathname === '/login'
    const navigate = useNavigate()

    // 登录成功的操作
    const handlerSuccessLogin = (res: any) => {
        message.success('Login Success!')
        setToken(res.token)
        setUserInfo(res.user_info)   
        //history.replace('/dashboard')
        //将跳转到dashboard页面改为如果地址为/login则跳转到/dashboard，如果带重定向则跳转到重定向地址
        if (search.split('?targetUrl=')[1]===undefined)
        {
            navigate('/dashboard')
        }
        else
        {
            window.location.href = search.split('?targetUrl=')[1]
        }
        
    }
    // 重置密码成功后的操作
    const handlerSuccessResetPassword = () => {
        message.success('Reset Password Success!')
        cleanToken()
        clearUserInfo()
        navigate('/login')
    }

    const onFinish = async (values: { username: string; password: string }) => {
        setLoginLoading(true)
        let params = {
            username: isLogin ? values.username : undefined,
            password: values.password,
            user_id: isLogin ? undefined : state.user_id
        }
        const handlerFn = isLogin ? login : resetPassword
        try {
            const res: any = await handlerFn(params)
            if (res.status_code === 0) {
                isLogin ? handlerSuccessLogin(res) : handlerSuccessResetPassword()
            } else {
                setLoginLoading(false)
                message.error(res.status_msg)
            }
        } catch (err) {
            setLoginLoading(false)
        }
    };

    useEffect(() => {
    }, [])

    return (
        <div className="login-wrapper">
            <Form
                name="normal_login"
                className="login-form"
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                        { required: true, message: '请输入手机号或账号!' },
                    ]}
                >
                    <Input disabled={pathname !== '/login'} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="手机号或账号" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入密码!' }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="密码"
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loginLoading}
                        className="login-form-button"
                    >
                        {isLogin ? '登录' : '重置密码'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default LoginForm
