import React from 'react'
import ReactEcharts from 'echarts-for-react'

const LinkBar = () => {
  const option = {
    title: {
      text: '门店业绩'
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['南京鼓楼店', '南京雨花店', '南京江宁店'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: ['2024-04-01', '2024-04-02', '2024-04-03', '2024-04-04', '2024-04-05', '2024-04-06', '2024-04-07']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: '南京鼓楼店',
        type: 'line',
        stack: 'Total',
        data: [120, 132, 101, 134, 90, 230, 210]
      },
      {
        name: '南京雨花店',
        type: 'line',
        stack: 'Total',
        data: [220, 182, 191, 234, 290, 330, 310]
      },
      {
        name: '南京江宁店',
        type: 'line',
        stack: 'Total',
        data: [150, 232, 201, 154, 190, 330, 410]
      },
    ]
  };

  return (
    <ReactEcharts option={option}/>
  )
}

const PieBar = () => {
  const option = {
    title: {
      text: '课程分析'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'right',
    },
    series: [
      {
        name: '课程分析',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 1048, name: '漫步舞蹈' },
          { value: 735, name: '腰腹塑形' },
          { value: 580, name: 'LES MILLS' },
          { value: 484, name: '燃脂热舞' },
          { value: 300, name: '肩颈瑜伽' }
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  return (
    <ReactEcharts option={option}/>
  )
}

const FunnelBar = () => {
  const option = {
    title: {
      text: '复购分析'
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c}人数'
    },
    legend: {
      data: ['总用户数', '付费人数', '复购人数'],
      orient: 'vertical',
      left: 'right',
    },
    series: [
      {
        name: '复购分析',
        type: 'funnel',
        left: '10%',
        top: 60,
        bottom: 60,
        width: '80%',
        min: 0,
        max: 100,
        minSize: '0%',
        maxSize: '100%',
        sort: 'descending',
        gap: 2,
        label: {
          show: true,
          position: 'inside'
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: 'solid'
          }
        },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1
        },
        emphasis: {
          label: {
            fontSize: 20
          }
        },
        data: [
          { value: 20, name: '复购人数' },
          { value: 80, name: '付费人数' },
          { value: 100, name: '总人数' }
        ]
      }
    ]
  };
  return (
    <ReactEcharts option={option}/>
  )
}


export { LinkBar, PieBar, FunnelBar }
