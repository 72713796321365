import React from 'react'
import { Col, Row } from 'antd';
import { LinkBar, FunnelBar, PieBar } from './charts';

const Dashboard = () => {
    return (
        <div>
            <Row>
                <Col span={12}><FunnelBar/></Col>
                <Col span={12}><PieBar/></Col>
            </Row>
            <LinkBar/>
        </div>
    )
}

export default Dashboard