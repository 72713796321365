import React, { useState } from 'react';
import {
  AppstoreOutlined,
  UserOutlined,
  OrderedListOutlined,
  ReadOutlined,
  ProductOutlined,
  AreaChartOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CrownOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Dropdown, Avatar, Col, Row, Button, message } from 'antd';
import { Outlet, Link, useLocation, useNavigate } from "react-router-dom"

import { cleanToken, clearUserInfo, getUserInfo } from "../../common/utils";
import './default.css'

const { Header, Content, Sider } = Layout;

const GetMenu = () => {
  return [{
    key: 'dashboard',
    icon: React.createElement(AreaChartOutlined),
    label: <Link to={'/dashboard'}>数据展示</Link>,
    
  }, {
    key: 'order',
    icon: React.createElement(OrderedListOutlined),
    label: <Link to={'/order'}>订单管理</Link>,
  }, {
    key: 'product',
    icon: React.createElement(ProductOutlined),
    label: <Link to={'/product'}>商品管理</Link>,
  }, {
    key: 'user',
    icon: React.createElement(UserOutlined),
    label: <Link to={'/user'}>用户管理</Link>,
  }, {
    key: 'store',
    icon: React.createElement(AppstoreOutlined),
    label: <Link to={'/store'}>门店管理</Link>,
  }, {
    key: 'course',
    icon: React.createElement(ReadOutlined),
    label: <Link to={'/course'}>课程管理</Link>,
  }, {
    key: 'activity',
    icon: React.createElement(CrownOutlined),
    label: <Link to={'/activity'}>活动管理</Link>,
  }]
}

const DefaultLayout: React.FC = (props) => {
  const navigate = useNavigate()
  const user_info = getUserInfo() || {};
  const [collapsed, setCollapsed] = useState(false);
  const breadcrumbs = useLocation().pathname.split("/")
  
  const handlerUserOperate = ({key}: any)=>{
    if (key === "logout") {
      cleanToken();
      clearUserInfo();
      message.info("退出成功")
      navigate('/login')
    }
  }
  const GetUserMenu = () => {
    var items = [
      { key: "logout", label: "退出登录" },
      // { key: "reset", label: "Reset Password" },
    ];
    return (
      <Menu onClick={handlerUserOperate} items={items}></Menu>
    );
  };
  const GetUser = () => {
    return <Dropdown overlay={GetUserMenu()}><div><Avatar src={user_info.avatar} icon={<UserOutlined />} />{user_info.username}</div></Dropdown>
  }
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  return (
    <Layout style={{ minHeight: '100vh' }} hasSider>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="default-logo">{collapsed ? 'YP' : 'YuPlus'}</div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={breadcrumbs} items={GetMenu()} />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Row>
            <Col flex={1}>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
            </Col>
            <Col style={{marginRight: 20}}>{GetUser()}</Col>
          </Row>
        </Header>
        <Content key='content' style={{ padding: '0px 16px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {breadcrumbs.map((name) => (name !== '' ? <Breadcrumb.Item key={name}>{name}</Breadcrumb.Item> : <></>))}
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              textAlign: 'center',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );

};

export default DefaultLayout;