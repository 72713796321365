import React, { useEffect, useState, forwardRef } from 'react';
import { DatePicker, Form, Input, Radio, Select, message } from 'antd';
import type { SelectProps } from 'antd';
import { articleTypeEnum, articleTypeEnumKeys } from '../../common/enum'
import { TencentCOSUpload, TencentCOSUploadPlugin } from '../../components/object_storage/tencent_cos'
import { token as osToken } from '../../common/api/tool'
import { list as productList } from '../../common/api/product'

import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, SourceEditing,
    Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import './detail.css';

const { RangePicker } = DatePicker;

const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};


const ArticleDetail = forwardRef((props: any, ref: any) => {
    const [form] = Form.useForm();
    const [cosData, setCOSData] = useState<any>(undefined);
    const [defUploadList, setDefUploadList] = useState<any>();
    const [productEnum, setProductEnum] = useState<SelectProps['options']>([]);
    useEffect(() => {
        setDefUploadList(undefined);
        if (props.data && props.data["res"]) {
            setDefUploadList(props.data["res"]);
        }
        form.resetFields()
        form.setFieldsValue(props.data)
        getToken();
        GetProductEnum();
    }, [props.data])

    const GetProductEnum = async () => {
        try {
            const res: any = await productList({ "page": 1, "size": 1000 })
            if (res.status_code !== 0) {
                message.error(res.status_msg)
                return
            }
            const data = res.product_list.map((item: any) => ({
                value: item.id,
                label: item.id,
            }));
            setProductEnum(data)
        } catch (error) {
            message.info("GetUserEnum catch err")
        }
    };

    const getToken = async () => {
        var req: any = await osToken("cos", "public/article/");
        setCOSData(req.data);
    };

    return (
        <Form
            ref={ref}
            name='store'
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={props.data}
            layout="horizontal"
            style={{ paddingTop: 20 }}
            onFinish={props.onFinish}
        >
            <Form.Item label="标题" name="title">
                <Input />
            </Form.Item>
            <Form.Item label="类型" name="type" initialValue="banner">
                <Radio.Group>
                    {articleTypeEnumKeys.map(key => <Radio value={key}>{articleTypeEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type}>
                {({ getFieldValue }) => (
                    getFieldValue('type') === 'banner' ? <Form.Item label="缩略图" name="res" valuePropName="fileList" getValueFromEvent={normFile}>
                        <TencentCOSUpload value={defUploadList} onChange={(uploadList) => { setDefUploadList(uploadList) }} maxCount={1} cosData={cosData}></TencentCOSUpload>
                    </Form.Item> : <></>
                )}
            </Form.Item>
            <Form.Item label="售卖商品" name="product_id">
                <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    notFoundContent={null}
                    options={productEnum}
                />
            </Form.Item>
            <Form.Item hidden name="content"><Input /></Form.Item>
            <Form.Item label="内容">
                <div className="ckeditor">
                    {cosData ? <CKEditor
                        editor={ClassicEditor}
                        config={{
                            plugins: [
                                Bold, Essentials, Italic, Mention, Paragraph, Undo, SourceEditing,
                                Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageUpload,
                            ],
                            toolbar: {
                                items: [
                                    'undo', 'redo', '|', 'bold', 'italic', '|', 'uploadImage', 'sourceEditing'
                                ]
                            },
                            image: {
                                resizeOptions: [
                                    {
                                        name: 'resizeImage:original',
                                        label: 'Default image width',
                                        value: null,
                                    },
                                    {
                                        name: 'resizeImage:50',
                                        label: '50% page width',
                                        value: '50',
                                    },
                                    {
                                        name: 'resizeImage:75',
                                        label: '75% page width',
                                        value: '75',
                                    },
                                    {
                                        name: 'resizeImage:100',
                                        label: '100% page width',
                                        value: '100',
                                    }
                                ],
                                toolbar: [
                                    'imageTextAlternative',
                                    'toggleImageCaption',
                                    '|',
                                    'imageStyle:inline',
                                    'imageStyle:wrapText',
                                    'imageStyle:breakText',
                                    '|',
                                    'resizeImage',
                                ],
                            }
                        }}
                        onChange={(_event: any, editor: ClassicEditor) => {
                            console.log("onChange", "---------------------")
                            const htmlContent = editor.getData().replace(/<figure[^>]*>/g, '').replace(/<\/figure>/g, '');
                            form.setFieldValue("content", htmlContent)
                        }}
                        onReady={(editor: ClassicEditor) => {
                            TencentCOSUploadPlugin(editor, cosData);
                        }}
                        data={props.data ? (props.data.content ? props.data.content : "") : ""}
                    /> : <></>}
                </div>
            </Form.Item>
            <Form.Item label="有效日期" name="date">
                <RangePicker />
            </Form.Item>
        </Form>
    );
})
export default ArticleDetail;
