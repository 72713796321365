import React, { useEffect, useState, forwardRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { DatePicker, Select, Form, Input, InputNumber , Radio } from 'antd';
import type { DefaultOptionType } from 'antd/es/cascader';
import { TencentCOSUpload } from '../../components/object_storage/tencent_cos'
import { token as osToken } from '../../common/api/tool'

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

interface Option {
    value: string;
    label: string;
    children?: Option[];
    disabled?: boolean;
}

// const options: Option[] = [
//     {
//         value: 'zhejiang',
//         label: 'Zhejiang',
//         children: [
//             {
//                 value: 'hangzhou',
//                 label: 'Hangzhou',
//                 children: [
//                     {
//                         value: 'xihu',
//                         label: 'West Lake',
//                     },
//                     {
//                         value: 'xiasha',
//                         label: 'Xia Sha',
//                         disabled: true,
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         value: 'jiangsu',
//         label: 'Jiangsu',
//         children: [
//             {
//                 value: 'nanjing',
//                 label: 'Nanjing',
//                 children: [
//                     {
//                         value: 'zhonghuamen',
//                         label: 'Zhong Hua men',
//                     },
//                 ],
//             },
//         ],
//     },
// ];

const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
        option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
    
const CourseDetail = forwardRef((props: any, ref: any) => {
    const [form] = Form.useForm();
    const [cosData, setCOSData] = useState();
    const [defUploadList, setDefUploadList] = useState<any>();
    const [thumbnail, setThumbnail] = useState<any>();
    
    useEffect(() => {
        form.resetFields()
        form.setFieldsValue(props.data)
        setDefUploadList(undefined);
        setThumbnail(undefined);
        if(props.data && props.data["res"]){
            setDefUploadList(props.data["res"]);
        }
        if(props.data && props.data["thumbnail"]){
            setThumbnail(props.data["thumbnail"]);
        }
        getToken();
    }, [props.data])

    const getToken = async () => {
        var req: any = await osToken("cos", "public/course/");
        // console.log("data", req.data)
        setCOSData(req.data);
    };
    return (
        <Form
            ref={ref}
            name='store'
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={props.data}
            layout="horizontal"
            style={{ paddingTop: 20 }}
            onFinish={props.onFinish}
        >
            <Form.Item label="课程名称" name="name">
                <Input />
            </Form.Item>
            {/* <Form.Item label="课程类型" name="type">
                <Cascader
                    options={options}
                    placeholder="Please select"
                    showSearch={{ filter }}
                    onSearch={value => console.log(value)}
                />
            </Form.Item> */}
            <Form.Item label="课程介绍" name="description">
                <TextArea />
            </Form.Item>
            <Form.Item label="课程时长" name="duration">
                <InputNumber />
            </Form.Item>
            <Form.Item label="课程标签" name="tags">
                <Select mode="tags"/>
            </Form.Item>
            <Form.Item label="缩略图" name="thumbnail" valuePropName="fileList" getValueFromEvent={normFile} shouldUpdate={true}>
                <TencentCOSUpload key="thumbnail" value={thumbnail} onChange={(uploadList)=>{setThumbnail(uploadList)}} maxCount={1} cosData={cosData}></TencentCOSUpload>
            </Form.Item>
            <Form.Item label="课程资源" name="res" valuePropName="fileList" getValueFromEvent={normFile} shouldUpdate={true}>
                <TencentCOSUpload key="res" value={defUploadList} onChange={(uploadList)=>{setDefUploadList(uploadList)}} maxCount={6} cosData={cosData}></TencentCOSUpload>
            </Form.Item>
            <Form.Item label="状态" name="status" initialValue="active">
                <Radio.Group>
                    <Radio value="active"> 正常 </Radio>
                    <Radio value="inactive"> 禁用 </Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    );
})
export default CourseDetail;
