import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Table, Space, Modal, message } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import type { SelectProps, TableColumnsType } from 'antd';
import ProductDetail from './detail';
import dayjs from 'dayjs';
import { statusEnum, statusEnumKeys, productTypeEnum, productTypeEnumKeys } from '../../common/enum'
import { ProductDataType as DataType } from '../../common/type'
import { list as userList } from '../../common/api/user'
import { list as courseList } from '../../common/api/course'
import { list as storeList } from '../../common/api/store'
import { action, list } from '../../common/api/product'

const Product: React.FC = () => {
  const [form] = Form.useForm();

  const [userValue, setUserValue] = useState<any>({});
  const [courseValue, setCourseValue] = useState<any>({});
  const [storeValue, setStoreValue] = useState<any>({});

  const [courseEnum, setCourseEnum] = useState<SelectProps['options']>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData] = useState<DataType | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState<DataType[]>([]);

  const detail = React.createRef<typeof form>();
  useEffect(() => {
    GetUserEnum()
    GetCourseEnum()
    GetStoreEnum()
    GetList()
  }, [])

  useEffect(() => {
    if (data !== undefined) {
      showModal()
    }
  }, [data])

  const columns: TableColumnsType<DataType> = [
    {
      title: '商品ID',
      dataIndex: 'id',
    },
    {
      title: '所属类型',
      dataIndex: 'type',
      render: (text) => productTypeEnum[text],
    },
    {
      title: '所属课程',
      dataIndex: 'course_id',
      render: (_, record) => record.type === "normal" ? courseValue[record.course_id] : "通用",
    },
    {
      title: '商品价格',
      dataIndex: 'price',
    },
    {
      title: '开课门店/次卡次数',
      render: (_, record) => record.type === "normal" ? (record.store ? storeValue[record.store.store_id] : "") : record.visit_card.visits,
    },
    {
      title: '开课教练/有效天数',
      render: (_, record) => record.type === "normal" ? (record.store ? userValue[record.store.coache_id] : "") : record.visit_card.expiration_day,
    },
    {
      title: '开课时间',
      render: (_, record) => record.type === "normal" ? (record.store ? record.store.date_time : "") : "",
    },
    {
      title: '库存',
      dataIndex: 'inventory',
      render: (text) => text === 0 || text === undefined ? "∞" : text
    },
    {
      title: '销售',
      dataIndex: 'sale_num',
      render: (text) => text
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text) => statusEnum[text],
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => <><a href='#edit' onClick={() => { onDetailEdit(record); }}>编辑</a> | <a href='#del' onClick={() => { onDetailDel(record) }}>删除</a></>,
    },
  ];

  const GetUserEnum = async () => {
    try {
      const res: any = await userList({ "page": 1, "size": 1000, "query": { "type": "coache" } })
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      let data: any = {}
      res.user_list.forEach((item: { id: string; nickname: string; }) => {
        data[item.id] = item.nickname
      });
      setUserValue(data)
    } catch (error) {
      message.info("GetUserEnum catch err")
    }
  };

  const GetCourseEnum = async () => {
    try {
      const res: any = await courseList({ "page": 1, "size": 1000 })
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      let data: any = {}
      let dataEnum: SelectProps['options'] = []
      res.course_list.forEach((item: { id: string; name: string; }) => {
        data[item.id] = item.name
        if(dataEnum){
          dataEnum.push({
            value: item.id,
            label: item.name,
          })
        }
      });
      setCourseValue(data)
      setCourseEnum(dataEnum)
    } catch (error) {
      message.info("GetCourseEnum catch err")
    }
  };

  const GetStoreEnum = async () => {
    try {
      const res: any = await storeList({ "page": 1, "size": 1000 })
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      let data: any = {}
      res.store_list.forEach((item: { id: string; name: string; }) => {
        data[item.id] = item.name
      });
      setStoreValue(data)
    } catch (error) {
      message.info("GetStoreEnum catch err")
    }
  };

  const GetList = async () => {
    try {
      const params = form.getFieldsValue()
      const res: any = await list({ "page": 1, "size": 20, "query": JSON.stringify(params) })
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      setDataList(res.product_list)
    } catch (error) {
      console.log("GetList catch err", error)
      message.info("GetList catch err")
    }
  }

  const showAdd = () => {
    setData(undefined);
    detail.current?.resetFields()
    showModal();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setData(undefined)
    detail.current?.resetFields()
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onDetailEdit = (record: any) => {
    var value = JSON.parse(JSON.stringify(record));
    if (value["type"] === 'normal' && value["store"] && value["store"]["date_time"]) {
      value["store"]["date"] = dayjs(value["store"]["date_time"], "YYYY-MM-DD")
      value["store"]["time"] = dayjs(value["store"]["date_time"], "YYYY-MM-DD HH:mm")
    }
    setData(value)
  }

  const onDetailDel = async (record: any) => {
    try {
      const res: any = await action(2, record)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailDel catch err")
    }
  }

  const onDetailFinish = async (value: any) => {
    console.log("value", value["store"]["date"], value["store"]["time"])
    if (data !== undefined) {
      value["id"] = data.id
    }
    if (value["price"]) {
      value["price"] = parseFloat(value["price"])
    }
    if (value["inventory"] !== undefined) {
      value["inventory"] = parseInt(value["inventory"])
    }
    if (value["type"] === 'normal' && value["store"] && value["store"]["date"] && value["store"]["time"]) {
      value["store"]["course_id"] = value["course_id"]
      value["store"]["date_time"] = dayjs(value["store"]["date"]).format("YYYY-MM-DD") + " " + dayjs(value["store"]["time"]).format("HH:mm")
      delete value["store"]["date"]
      delete value["store"]["time"]
    }
    if (value["type"] === 'visit_card' && value["visit_card"]) {
      value["visit_card"]["course_id"] = value["course_id"]
      value["visit_card"]["expiration_day"] = parseInt(value["visit_card"]["expiration_day"])
      value["visit_card"]["visits"] = parseInt(value["visit_card"]["visits"])
    }
    try {
      const res: any = await action(1, value)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      detail.current?.resetFields()
      setOpen(false)
      setData(undefined)
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailFinish catch err")
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 16, textAlign: "left" }}>
        <Form
          layout="inline"
          form={form}
          initialValues={{ layout: "inline" }}
          onFinish={GetList}
        >
          <Form.Item label="所属课程" name="course_id">
            <Select
              style={{ minWidth: '140px' }}
              showSearch
              defaultActiveFirstOption={false}
              suffixIcon={null}
              filterOption={false}
              notFoundContent={null}
              options={courseEnum}
              allowClear
            />
          </Form.Item>
          <Form.Item label="商品类型" name="type" initialValue="normal">
            <Select style={{ minWidth: '100px' }} placeholder="请选择要查询的商品类型">
              {productTypeEnumKeys.map(key => <Select.Option value={key}>{productTypeEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="状态" name="status" initialValue="active">
            <Select style={{ minWidth: '100px' }} placeholder="请选择要查询的状态">
              {statusEnumKeys.map(key => <Select.Option value={key}>{statusEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary" size="small" shape="circle" icon={<SearchOutlined />} />
              <Button onClick={showAdd} type="primary" size="small" shape="circle" icon={<PlusOutlined />} />
            </Space>
          </Form.Item>
        </Form>

      </div>
      <Table rowKey="id" size="small" bordered rowSelection={rowSelection} columns={columns} dataSource={dataList} pagination={{ showSizeChanger: true }} />
      <Modal maskClosable={false} open={open} onCancel={handleCancel} title={data?.id ? "编辑" : "添加"} onOk={() => {
        if (detail.current) {
          detail.current.submit()
        }
      }}>
        <ProductDetail ref={detail} data={data} onFinish={onDetailFinish}></ProductDetail>
      </Modal>
    </div>
  );
};

export default Product;