export const statusEnum: { [key: string]: string } = {
    active: "正常",
    inactive: "禁用",
}

export const statusEnumKeys = Object.keys(statusEnum);

export const userGenderEnum: { [key: string]: string } = {
    male: "男",
    female: "女",
    other: "其他",
}
export const userGenderEnumKeys = Object.keys(userGenderEnum);

export const userTypeEnum: { [key: string]: string } = {
    coache: "教练",
    user: "普通用户",
}

export const userTypeEnumKeys = Object.keys(userTypeEnum);

export const productTypeEnum: { [key: string]: string } = {
    normal: "常规",
    visit_card: "次卡",
}

export const productTypeEnumKeys = Object.keys(productTypeEnum);

export const storeTypeEnum: { [key: string]: string } = {
    direct: "直营",
    agency: "代理",
}

export const storeTypeEnumKeys = Object.keys(storeTypeEnum);

export const orderStatusEnum: { [key: string]: string } = {
    pending_payment: "待支付",
    pending_appointment: "待预约",
    pending_complet: "待完成",
    completed: "已完成",
    cancelled: "已取消",
    refund_processing: "退款中",
    partial_refund_processing: "部分退款中",
    partial_refunded: "部分退款",
    refunded: "已退款",
}

export const orderStatusEnumKeys = Object.keys(orderStatusEnum);

export const articleTypeEnum: { [key: string]: string } = {
    banner: "轮播图",
    article: "文章",
}

export const articleTypeEnumKeys = Object.keys(articleTypeEnum);