import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Table, Space, Modal, message } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import StoreDetail from './detail';
import dayjs from 'dayjs';
import { statusEnum, statusEnumKeys, storeTypeEnum } from '../../common/enum'
import { StoreDataType as DataType } from '../../common/type'
import { action, list } from '../../common/api/store'

const Store: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData] = useState<DataType|undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState<DataType[]>([]);
  const detail = React.createRef<typeof form>();
  useEffect(()=>{
    GetList()
  }, [])
  useEffect(()=>{
    if(data !== undefined){
      showModal()
    }
  }, [data])
  const onDetailEdit = (record: any)=>{
    var value = JSON.parse(JSON.stringify(record));
    if(value["res"]){
      for (const key in value["res"]) {
        value["res"][key] = JSON.parse(value["res"][key]);
      }
    }
    value.business_hours = [dayjs(value.start_business_hours, "HH:mm"), dayjs(value.end_business_hours, "HH:mm")]
    setData(value)
  }
  const columns: TableColumnsType<DataType> = [
    {
      title: '门店名称',
      dataIndex: 'name',
    },
    {
      title: '门店类型',
      dataIndex: 'type',
      render: (text) => storeTypeEnum[text],
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
    },
    {
      title: '门店地址',
      dataIndex: 'address',
    },
    {
      title: '营业时间',
      render: (_, record) => <span>{record.start_business_hours}-{record.end_business_hours}</span>,
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text) => statusEnum[text],
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => <><a href='#edit' onClick={()=>{onDetailEdit(record);}}>编辑</a> | <a href='#del' onClick={()=>{onDetailDel(record)}}>删除</a></>,
    },
  ];
  const GetList = async ()=>{
    try {
      const params = form.getFieldsValue()
      const res: any = await list({"page": 1, "size": 20, "query": JSON.stringify(params)})
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      setDataList(res.store_list)
    } catch (error) {
      message.info("GetList catch err")
    }
  }

  const showAdd = () => {
    setData(undefined);
    detail.current?.resetFields()
    showModal();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setData(undefined)
    detail.current?.resetFields()
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onDetailDel = async (record: any)=>{
    try {
      const res: any = await action(2, record)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailDel catch err")
    }
  }

  const onDetailFinish = async (value: any) => {
    if (value["business_hours"] && value["business_hours"].length === 2) {
      value["start_business_hours"] = dayjs(value["business_hours"][0]).format('HH:mm')
      value["end_business_hours"] = dayjs(value["business_hours"][1]).format('HH:mm')
      delete value["business_hours"]
    }
    if (data !== undefined) {
      value["id"] = data.id
    }
    if(value["res"]){
      for (const key in value["res"]) {
        value["res"][key] = JSON.stringify(value["res"][key]);
      }
    }
    try {
      const res: any = await action(1, value)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      detail.current?.resetFields()
      setOpen(false)
      setData(undefined)
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailFinish catch err")
    }
  }
  return (
    <div>
      <div style={{ marginBottom: 16, textAlign: "left" }}>
        <Form
          layout="inline"
          form={form}
          initialValues={{ layout: "inline" }}
          onFinish={GetList}
        >
          <Form.Item label="门店名称" name="name">
            <Input placeholder="请输入要查询的门店名称" />
          </Form.Item>
          <Form.Item label="联系电话" name="phone">
            <Input placeholder="请输入要查询的联系电话" />
          </Form.Item>
          <Form.Item label="门店地址" name="address">
            <Input placeholder="请输入要查询的门店地址" />
          </Form.Item>
          <Form.Item label="状态" name="status" initialValue="active">
            <Select style={{ minWidth: '100px' }} placeholder="请选择要查询的状态" allowClear>
              {statusEnumKeys.map(key => <Select.Option value={key}>{statusEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary" size="small" shape="circle" icon={<SearchOutlined />} />
              <Button onClick={showAdd} type="primary" size="small" shape="circle" icon={<PlusOutlined />} />
            </Space>
          </Form.Item>
        </Form>

      </div>
      <Table rowKey="id" size="small" bordered rowSelection={rowSelection} columns={columns} dataSource={dataList} pagination={{ showSizeChanger: true }}/>
      <Modal maskClosable={false} open={open} onCancel={handleCancel} title={data?.id?"编辑":"添加"} onOk={() => {
        if (detail.current) {
          detail.current.submit()
        }
      }}>
        <StoreDetail ref={detail} data={data} onFinish={onDetailFinish}></StoreDetail>
      </Modal>
    </div>
  );
};

export default Store;