import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Table, Space, Modal, message, Tag } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import CourseDetail from './detail';
import { statusEnum, statusEnumKeys } from '../../common/enum'
import { CourseDataType as DataType } from '../../common/type'
import { action, list } from '../../common/api/course'

const Course: React.FC = () => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [data, setData] = useState<DataType|undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState<DataType[]>([]);
  const detail = React.createRef<typeof form>();
  useEffect(()=>{
    GetList()
  }, [])
  useEffect(()=>{
    if(data !== undefined){
      showModal()
    }
  }, [data])
  const onDetailEdit = (record: any)=>{
    var value = JSON.parse(JSON.stringify(record));
    if(value["res"]){
      for (const key in value["res"]) {
        value["res"][key] = JSON.parse(value["res"][key]);
      }
    }
    if(value["thumbnail"]){
      value["thumbnail"] = [
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: value["thumbnail"],
        }
    ]
    }
    setData(value)
  }
  const columns: TableColumnsType<DataType> = [
    {
      title: '课程名称',
      dataIndex: 'name',
    },
    {
      title: '课程描述',
      dataIndex: 'description',
    },
    {
      title: '课程时长',
      dataIndex: 'duration',
    },
    {
      title: '课程标签',
      dataIndex: 'tags',
      render: (tags) => {
        if(tags === undefined) return <></>
        return tags.map((name: string) => <Tag color="success">{name}</Tag>)
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      render: (text) => statusEnum[text],
    },
    {
      title: '操作',
      dataIndex: 'action',
      fixed: 'right',
      width: 100,
      render: (_, record) => <><a href='#edit' onClick={()=>{onDetailEdit(record);}}>编辑</a> | <a href='#del' onClick={()=>{onDetailDel(record)}}>删除</a></>,
    },
  ];

  const GetList = async ()=>{
    try {
      const params = form.getFieldsValue()
      const res: any = await list({"page": 1, "size": 20, "query": JSON.stringify(params)})
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      setDataList(res.course_list)
    } catch (error) {
      message.info("GetList catch err")
    }
  }

  const showAdd = () => {
    setData(undefined);
    detail.current?.resetFields()
    showModal();
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setData(undefined)
    detail.current?.resetFields()
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onDetailDel = async (record: any)=>{
    try {
      const res: any = await action(2, record)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailDel catch err")
    }
  }

  const onDetailFinish = async (value: any) => {
    if (data !== undefined) {
      value["id"] = data.id
    }
    if(value["res"]){
      for (const key in value["res"]) {
        value["res"][key] = JSON.stringify(value["res"][key]);
      }
    }
    if(value["thumbnail"]){
      for (const key in value["thumbnail"]) {
        value["thumbnail"] = value["thumbnail"][key]["url"];
      }
    }
    try {
      const res: any = await action(1, value)
      if (res.status_code !== 0) {
        message.error(res.status_msg)
        return
      }
      detail.current?.resetFields()
      setOpen(false)
      setData(undefined)
      GetList()
      message.info(res.status_msg)
    } catch (error) {
      message.info("onDetailFinish catch err")
    }
  }

  return (
    <div>
      <div style={{ marginBottom: 16, textAlign: "left" }}>
        <Form
          layout="inline"
          form={form}
          initialValues={{ layout: "inline" }}
          onFinish={GetList}
        >
          <Form.Item label="课程名称" name="name">
            <Input placeholder="请输入要查询的课程名称" />
          </Form.Item>
          {/* <Form.Item label="课程类型" name="type">
                <Cascader
                    options={options}
                    placeholder="Please select"
                    showSearch={{ filter }}
                    onSearch={value => console.log(value)}
                />
            </Form.Item> */}
          <Form.Item label="状态" name="status" initialValue="active">
            <Select style={{ minWidth: '200px' }} placeholder="请选择要查询的状态" allowClear>
              {statusEnumKeys.map(key => <Select.Option value={key}>{statusEnum[key]}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary" size="small" shape="circle" icon={<SearchOutlined />} />
              <Button onClick={showAdd} type="primary" size="small" shape="circle" icon={<PlusOutlined />} />
            </Space>
          </Form.Item>
        </Form>
      </div>
      <Table rowKey="id" size="small" bordered rowSelection={rowSelection} columns={columns} dataSource={dataList} pagination={{ showSizeChanger: true }}/>
      <Modal maskClosable={false} open={open} onCancel={handleCancel} title={data?.id?"编辑":"添加"} onOk={() => {
        if (detail.current) {
          detail.current.submit()
        }
      }}>
        <CourseDetail ref={detail} data={data} onFinish={onDetailFinish}></CourseDetail>
      </Modal>
    </div>
  );
};

export default Course;