import React, { useEffect, useState, forwardRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { TimePicker , Form, Input, Radio, Space, Upload } from 'antd';
import { statusEnum, statusEnumKeys, storeTypeEnum, storeTypeEnumKeys } from '../../common/enum'
import { TencentCOSUpload } from '../../components/object_storage/tencent_cos'
import { token as osToken } from '../../common/api/tool'
const { TextArea } = Input;
const normFile = (e: any) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const StoreDetail = forwardRef((props: any, ref: any) => {
    const [form] = Form.useForm();
    const [cosData, setCOSData] = useState();
    const [defUploadList, setDefUploadList] = useState<any>();
    useEffect(() => {
        form.resetFields()
        form.setFieldsValue(props.data)
        setDefUploadList(undefined);
        if(props.data && props.data["res"]){
            setDefUploadList(props.data["res"]);
        }
        getToken();
    }, [props.data])

    const getToken = async () => {
        var req: any = await osToken("cos", "public/store/");
        // console.log("data", req.data)
        setCOSData(req.data);
    };
    
    return (
        <Form
            ref={ref}
            name='store'
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={props.data}
            layout="horizontal"
            style={{ paddingTop: 20 }}
            onFinish={props.onFinish}
        >
            <Form.Item label="门店名称" name="name">
                <Input />
            </Form.Item>
            <Form.Item label="类型" name="type" initialValue="direct">
                <Radio.Group>
                    {storeTypeEnumKeys.map(key => <Radio value={key}>{storeTypeEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item label="联系电话" name="phone">
                <Input />
            </Form.Item>
            <Form.Item label="门店介绍" name="description">
                <TextArea />
            </Form.Item>
            <Form.Item label="门店地址" name="address">
                <Input />
            </Form.Item>
            <Form.Item label="门店坐标">
                <Space direction="horizontal">
                    <Form.Item noStyle name="location">
                        <Input />
                    </Form.Item>
                    <a href='https://lbs.qq.com/getPoint/' target="_blank">获取位置</a>
                </Space>
            </Form.Item>
            <Form.Item label="门店资源" name="res" valuePropName="fileList" getValueFromEvent={normFile} shouldUpdate={true}>
                <TencentCOSUpload value={defUploadList} onChange={(uploadList)=>{setDefUploadList(uploadList)}} maxCount={6} cosData={cosData}></TencentCOSUpload>
            </Form.Item>
            <Form.Item label="营业时间" name="business_hours">
                <TimePicker.RangePicker format="HH:mm" minuteStep={10} disabledHours={()=>[0,1,2,3,4,5,6]}/>
            </Form.Item>
            <Form.Item label="状态" name="status" initialValue="active">
                <Radio.Group>
                    {statusEnumKeys.map(key => <Radio value={key}>{statusEnum[key]}</Radio>)}
                </Radio.Group>
            </Form.Item>
        </Form>
    );
})
export default StoreDetail;
